import React from 'react'
import logo from '../Header/WKL-logo-white-Png.png'
import footerlogo from './Frame 360.svg'
import { NavLink } from 'react-router-dom'
export default function Footer() {
  return (
    <>
    <section className="footersection">
        <div className='row'>
            <div className='socialinks'>

                <ul className='footer-sociallinks'>
                    <li>
                    <a target='_blank' href="https://www.facebook.com/profile.php?id=61567005626564" class="mr-2"><i class="fa fa-facebook-official" aria-hidden="true"></i>
                    </a>
                    </li>

                    <li>
                    <a target='_blank' href="https://www.linkedin.com/company/105152735/admin/dashboard/" class="mr-2"><i class="fa fa-linkedin-square" aria-hidden="true"></i>
                    </a>
                    </li>

                    <li>
                    <a target='_blank' href="https://www.instagram.com/wklindia/" class="mr-2"><i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    </li>

                    <li>
                    <a  target='_blank' href="https://www.youtube.com/@wklindiaoffical" class="mr-2"><i class="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                    </li>
                </ul>

            </div>


            <div className='footer-link'>

<ul className='footer-navlink'>
    <li>
    <NavLink to="/" class="mr-2">HOME
    </NavLink>
    </li>

    <li>
    <NavLink to="/ABOUT" class="mr-2">ABOUT
    </NavLink>
    </li>

    <li>
    <NavLink to="/team" class="mr-2">TEAMS
    </NavLink>
    </li>

    <li>
    <NavLink to="/" class="mr-2">STANDINGS
    </NavLink>
    </li>

    <li>
    <a href="#" class="mr-2">NEWS
    </a>
    </li>

   
</ul>
{/* <img src={footerlogo} className='img-fluid'/> */}
</div>
        </div>
    </section>

    <section className='copy-right'>
  <img src={logo} className='img-fluid'/>
<p>2024 Women's Kabaddi League. All Rights Reserved.</p>
    </section>
    </>

  )
}
