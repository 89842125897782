import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner from './banner22.jpg';
import NewCard from './NewCard';
import Highlight from './Highlight';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Standing from '../Standing';
import banner10 from './Banner3.jpg'
import { Link } from 'react-router-dom';


const Mbanner = () => {
  const slides = [
    {
      id: 0,
      title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image: banner,
    }
  ];

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout:7000,
    autoplayHoverPause: true,
    items: 1, // Number of items to display
  };

  return (
    <>
<Link to="/player-registration">
<img src={banner} className='img-fluid mobileshow'/>
</Link>
    </>
  );
};

export default Mbanner;
